label, input, p, ul, span {
    
	font-size: 13px;
	font-variant: normal;
	padding: 0;
	margin: 0;
  }
  
  html {
	height: 100%;
  }
  
  body {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	display: flex;
	justify-content: center;
	min-height: 100%;
  }
  body::before{
	content: "";
	position: fixed;
	left: 0;
	top: 0;
	z-index: -1;
	display: block;
	background-image: url('./img/background-image.png');
	background-size:cover;
	width: 100%;
	height: 100%;
	filter: blur(3px);
  }
  h1, h2, h3 , h4, h5, h6{
	  margin: 5px 0;
  }
  h4{
	  font-size: 14px;
  }
  h6{
	  padding-top: 10px;
  }
  ul{
	  list-style: none;
  }
  ul li{
	  margin-bottom: 5px;
	  padding-bottom: 15px;
  }
  ul li span:first-child{
	  position: relative;
	  width: 150px;
	  display: inline-block;
  }
  ul li span{
	  font-size: 15px;
  }
  .list-initial::after {
	  content: ":";
	  position: absolute;
	  right: 10px;
  }
  .download-app img{
	 width: 150px; 
  }
  .download-app a:first-child{
	float: left;
  }
  
  .download-app a:nth-child(2){
	float: right;
  }
  
  .download-app::after{
	  clear: both;
	  content: "";
  }
  
  form {
	width: 480px;
	margin: 0 auto;
  }
  
  .group {
	background: #fff;
    box-shadow: 0 1px 2px 1px rgb(105 105 105 / 45%);
    border-radius: 8px;
    margin-top: 15px;
  }
  
  label {
	position: relative;
	color: #8898AA;
	font-weight: 300;
	height: 40px;
	line-height: 40px;
	display: flex;
	flex-direction: row;
  }
  
  .group label:not(:last-child) {
	border-bottom: 1px solid #F0F5FA;
  }
  
  .dark{
	  background-color: rgba(0, 0, 0, .05);
  }
  
  .upper span{
	  padding-top: 15px;
  }
  
  .upper span, .upper input, .upper select{
	 display: block;
	 width: 100%;
	 outline: none;
  }
  
  .upper input.pay-fields{
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgb(220, 110, 125);
	color: #000;
	font-size: 16px;
	margin-top: 10px;
	padding-left: 10px;
	box-sizing: border-box;
	border-radius: 0;
	max-width: 406px;
	width: 100%;
  }
  
  .upper span i{
	  color: red;
	  font-size: 14px;
  }
  
  .card-info{
	  box-shadow: 0px 1px 2px 1px rgb(105 105 105 / 45%);
	  border-radius: 10px;
	  padding: 0 10px;
  }
  
  #card-errors{
	  padding: 8px 0 0 11px;
  }
  
  .card-info select, .card-info input{
	line-height: 40px;
	height: 40px;
	background-color: transparent;
	border: none;
  }
  .card-info select{
	padding-left: 6px;
	font-size: 15px;
  }
  
  div.group label > span {
	width: 120px;
	text-align: right;
	margin-right: 30px;
  }
  
  .field {
	background: transparent;
	font-weight: 300;
	border: 0;
	color: #31325F;
	outline: none;
	flex: 1;
	padding-right: 10px;
	padding-left: 10px;
	cursor: text;
  }
  
  .field::-webkit-input-placeholder {
	color: #CFD7E0;
  }
  
  .field::-moz-placeholder {
	color: #CFD7E0;
  }
  
  #zip{
	  outline: none;
	  font-size: 16px;
  }
  
  #zip::placeholder, span.cc-number{
	  font-weight: 300;
	  font-family: sans-serif;
	  color: #000;
	  font-size: 13px;
	  letter-spacing: 0.4px;
  }
  
  span.cc-number{
	  position: relative;
	  top: 2px;
  }
  
  button#pay-submit {
	float: left;
	display: block;
	background-color: rgb(220, 110, 125);
	border: solid 1px rgba(255, 138, 154, 1);
	color: white;
	box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	border: 0;
	margin-top: 20px;
	font-size: 15px;
	font-weight: 400;
	width: 100%;
	height: 40px;
	line-height: 38px;
	outline: none;
	cursor: pointer;
  }
  
  
  
  .outcome {
	float: left;
	width: 100%;
	padding-top: 8px;
	min-height: 24px;
	text-align: center;
  }
  
  .success,
  .error {
	display: none;
	font-size: 13px;
  }
  
  .success.visible,
  .error.visible {
	display: inline;
  }
  
  .error {
	color: #E4584C;
  }
  
  .success {
	color: #666EE8;
  }
  
  .success .token {
	font-weight: 500;
	font-size: 13px;
  }
  
  .location span{
	position: relative;
	top: -11px;
	font-size: 18px;
  }
  
  .pay-duration{
	  margin-top: 10px;
	  max-width: 406px;
  }
  
  .pay-duration span{
	  float: left;
	  display: block;
	  width: 100%;
	  max-width: 47.5%;
  }
  
  .pay-duration select{
	  text-align: center;
	  text-align-last: center;
	  border: none;
	  border-bottom: 1px solid rgb(220, 110, 125);
	  background-color: transparent;
	  border-radius: 0;
	  font-size: 16px;
  }
  
  .country-with-phone{
	  position: relative;
  }
  
  .country-with-phone .country-code {
	  width: 85px;
	  padding: 5px 0;
	  position: absolute;
	  top: -9px;
  }
  
  .country-with-phone .input-wrapper {
	  width: 100%;
  }
  
  .loader,
  .loader:after {
	border-radius: 50%;
	width: 50px;
	height: 50px;
  }
  .loader {	
	font-size: 10px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-indent: -9999em;
	border-top: 1.1em solid rgba(255, 255, 255, 0.6);
	border-right: 1.1em solid rgba(255, 255, 255, 0.6);
	border-bottom: 1.1em solid rgba(255, 255, 255, 0.6);
	border-left: 1.1em solid rgb(220, 110, 125);
	-webkit-animation: load8 0.5s infinite linear;
	animation: load8 0.5s infinite linear;
  }
  @-webkit-keyframes load8 {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @keyframes load8 {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  
  .react-custom-flag-select__ellipsis___1ZnQ3 {
	  white-space: nowrap;
	  overflow: hidden;
	  text-overflow: ellipsis
  }
  
  .react-custom-flag-select__select__button___15AP2 {
	  width: 100%;
	  display: inline-block;
	  text-align: left;
	  cursor: pointer;
	  background: none;
	  border: none;
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  overflow: visible;
	  outline: none;
  }
  
  .react-custom-flag-select__select__wrapper___1R93I.react-custom-flag-select__disabled___2j0z7,
  .react-custom-flag-select__select__wrapper___1R93I.react-custom-flag-select__disabled___2j0z7 div {
	  cursor: not-allowed
  }
  
  .react-custom-flag-select__select__wrapper___1R93I {
	  cursor: pointer;
	  outline: none;
	  position: relative;
	  height: 100%
  }
  
  .react-custom-flag-select__select__input___14Lze {
	  opacity: 0;
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  width: 100%;
	  height: 100%;
	  cursor: pointer
  }
  
  .react-custom-flag-select__select__buttonWrapper___12YOr {
	  height: 100%
  }
  
  .react-custom-flag-select__select__searchInputWrapper___2YwS_ {
	  padding: 6px;
	  box-sizing: border-box;
	  width: 100%;
	  position: relative;
	  display: flex;
	  background-color: #fff
  }
  
  .react-custom-flag-select__select__searchInputSearchIcon___2oJfw {
	  position: absolute;
	  top: 50%;
	  left: 8px;
	  transform: translateY(-50%)
  }
  
  .react-custom-flag-select__select__searchInputRemoveIcon___2Fd43 {
	  position: absolute;
	  top: 50%;
	  right: 8px;
	  transform: translateY(-50%);
	  cursor: pointer
  }
  
  .react-custom-flag-select__select__searchInput___3Q6TN {
	  padding: 0 20px;
	  box-sizing: border-box;
	  border-radius: 2px;
	  font-size: 14px;
	  height: 40px;
	  width: 100%;
	  border: 1px solid #dfe2e7;
	  color: #7f8fa4;
	  display: block
  }
  
  .react-custom-flag-select__select__container___2FIjR {
	  position: relative;
	  height: 100%
  }
  
  .react-custom-flag-select__select__options-item___3_6R4 {
	  cursor: none;
	  background: #fff;
	  color: #4a4a4a;
	  transition: background .4s, color .2s;
	  padding: 10px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between
  }
  
  .react-custom-flag-select__select__options-item___3_6R4 div {
	  display: inline-block;
	  text-align: right;
	  font-size: 12px;
  }
  
  .react-custom-flag-select__select__options-item-show-cursor___24_5- {
	  cursor: pointer
  }
  
  .react-custom-flag-select__select__no-mouse___3ka1G {
	  cursor: none
  }
  
  .react-custom-flag-select__select__hover-active___1ECCY {
	  background: #ff5a57;
	  color: #fff
  }
  
  .react-custom-flag-select__select__options-item___3_6R4.react-custom-flag-select__active___2SYE9 {
	  background: rgb(220, 110, 125);
	  color: #fff
  }
  
  .react-custom-flag-select__select__options-container-animate___zn9Fe {
	  opacity: 0;
	  filter: alpha(opacity=0);
	  position: absolute;
	  left: 0;
	  z-index: -1;
	  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12), 0 0 10px 0 rgba(0, 0, 0, .08);
	  transform: translateY(-10px);
	  transition: all .4s;
	  visibility: hidden
  }
  
  .react-custom-flag-select__select__options-container-animate___zn9Fe.react-custom-flag-select__show___1cXxR {
	  transform: translate(-50%) scale(1);
	  opacity: 1;
	  z-index: 3;
	  visibility: visible
  }
  
  .react-custom-flag-select__select__options-container___1TPJG {
	  opacity: 0;
	  filter: alpha(opacity=0);
	  position: absolute;
	  left: 0;
	  z-index: -1;
	  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12), 0 0 10px 0 rgba(0, 0, 0, .08);
	  transform: translateY(-10px);
	  visibility: hidden
  }
  
  .react-custom-flag-select__select__options-container___1TPJG.react-custom-flag-select__show___1cXxR {
	  transform: translateY(0);
	  opacity: 1;
	  filter: alpha(opacity=100);
	  z-index: 1000;
	  visibility: visible;;
  }
  
  .react-custom-flag-select__select__options-container___1TPJG.react-custom-flag-select__show___1cXxR div:nth-child(2) {
	  margin-top: 0 !important;
  }
  
  .react-custom-flag-select__select__dropdown___Pf01k {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  height: 100%
  }
  
  .react-custom-flag-select__select__dropdown-icon___3KppP {
	  position: relative;
	  width: 2%;
	  display: inline-block;
	  vertical-align: middle;
	  width: 10px;
	  height: 20px
  }
  
  .react-custom-flag-select__select__selector___1JJ_x {
	  padding: 0 10px;
	  height: 100%
  }
  
  .react-custom-flag-select__select__dropdown-flag___4-6D7 {
	  /* flex: 0 0 30%;
	  width: 30%; */
	  display: inline-block;
	  vertical-align: middle;
	  height: 100%
  }
  
  .react-custom-flag-select__select__dropdown-name___TxNpJ {
	  display: flex;
	  justify-content: flex-end;
	  align-items: center;
	  flex: 0 0 70%;
	  width: 68%;
	  text-align: right;
	  display: inline-block;
	  vertical-align: middle
  }
  
  .react-custom-flag-select__select__dropdown-name___TxNpJ div {
	  display: inline-block;
	  vertical-align: middle
  }
  
  .react-custom-flag-select__select__dropdown-icon___3KppP.react-custom-flag-select__showArrow___2E92p:before {
	  content: "";
	  position: absolute;
	  top: 50%;
	  transform: translateY(-50%);
	  display: block;
	  width: 0;
	  border-color: #e3e3e3 transparent;
	  border-style: solid;
	  border-width: 5px 5px 0
  }
  
  .react-custom-flag-select__select__dropdown-icon-container___1hWQW {
	  position: relative
  }
  
  .pay-card{
	  position: relative;
	  color: #8898AA;
	  font-weight: 300;
	  height: 40px;
	  line-height: 40px;
	  display: flex;
	  flex-direction: row;
  }
  
  .pay-card > div{
	  flex: 1 1;
  }
  
  .error-msg{
	  font-size: 12px;
	  color: #cc0000;
	  line-height: 12px;
	  font-weight: 400;
	  padding: 3px 0;
  }

  .group label input.field::placeholder {
    color: #6b6b6b;
    font-size: 13px;
  }

  .group label input.field{
	  font-size: 15px;
  }
  
  .em-custom{
	  padding-top: 5px;
	  padding-left: 15px;
  }
  
  .upper input.mobile-input{
	  padding-left: 80px;
	  box-sizing: border-box;
  }
  
  .success-message {
	  text-align: center;
	  width: 300px;
	  position: absolute;
	  top: 11%;
	  left: 50%;
	  transform: translateX(-50%);
	  height: 180px;
	  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .12), 0 0 10px 0 rgba(0, 0, 0, .08);
	  background-color: #fff;
	  z-index: 100;
	  padding-top: 25px;
	}
  
	#sm-close{
		position: absolute;
		color: #b5b5b5;
		right: 10px;
		top: 1px;
		font-size: 34px;
		line-height: 34px;
		cursor: pointer;	
	}
	
	.success-message__icon {
	  max-width: 75px;
	}
	
	.success-message__title {
	  color: #3dc480;
	  transform: translateY(25px);
	  opacity: 1;
	  transition: all 200ms ease;
	}
	.active .success-message__title {
	  transform: translateY(0);
	  opacity: 1;
	}
	
	.success-message__content {
	  color: #b8babb;
	  transform: translateY(25px);
	  /* opacity: 0; */
	  transition: all 200ms ease;
	  transition-delay: 50ms;
	}
	.active .success-message__content {
	  transform: translateY(0);
	  opacity: 1;
	}
	
	.icon-checkmark circle {
	  fill: #3dc480;
	  transform-origin: 50% 50%;
	  /* transform: scale(0); */
	  transition: transform 200ms cubic-bezier(0.22, 0.96, 0.38, 0.98);
	}
	.icon-checkmark path {
	  transition: stroke-dashoffset 350ms ease;
	  transition-delay: 100ms;
	}
	.active .icon-checkmark circle {
	  transform: scale(1);
	}
	label#register{
		height: auto;
		line-height: 14px;
		margin-top: 20px;
		color: #464646;
		cursor: pointer;
		display: inline-block;
	}
	label#register input{
	  margin-right: 5px;
	}
	.lot-details{
		background-color: #fff;
		max-height: calc(100vh - 132px);
		overflow-y: scroll;
		width: 280px;
		position: fixed;
		top: 32px;
		  left: 50%;
		  transform: translateX(-50%);
		z-index: 101;
		padding: 10px;
	}
	.lot-details h4{
		padding-top: 7.5px;
	}
	.lot-details tr th{
	  font-size: 13px;
	  text-align: center;
	  width: 100px;
	}
	.lot-details tr td{
		font-size: 12px;
		text-align: center;
	}
	.lot-details tbody tr{
		padding-top: 5px;
	}
  @media (max-width: 500px) {
	#root{
		padding: 0 10px;
	}	
	form {
	  width: 100%;
	  margin: 5px 0;
	}
	.loader {	
		left: 35%;
	}
  }